<button
  *ngIf="show$ | async"
  mat-icon-button
  [helpTooltip]="tip$ | async"
  [matTooltipPosition]="position"
  [matTooltipShowDelay]="300"
  [subject]="subject$ | async"
  (click)="openArticle($event)"
>
  <mat-icon class="zef-icon-small" [color]="color$ | async">help_center</mat-icon>
</button>
